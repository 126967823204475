<template>
    <div>
        <div class="card-body py-1">
            <div id="custom-editor" ref="editorContainer" @drop="(e) => e.preventDefault()">
                <VueEditor
                    :ref="`widget_${index}`"
                    v-model="subtitleInput"
                    placeholder="Write your question here."
                    :editor-toolbar="customToolbar"
                    :editor-options="editorSettings"
                    @text-change="updateData"
                    @focus="setCursorOnFocus"
                    @blur="onEditorBlur"
                ></VueEditor>
            </div>
            <label class="f-11 text-muted d-flex justify-content-between font-weight-bold mt-2">
                <span>Answer Options</span>
                <span>Correct?</span>
            </label>

            <BooleanOptions
                v-if="showBooleanOptions"
                v-model="booleanOptions"
                :focus-index="focusIndex"
                :is-boolean="true"
                @clearFocusIndex="clearFocusIndex"
            ></BooleanOptions>
            <MultiOptions
                v-if="!showBooleanOptions"
                v-model="itemData.options"
                class="mb-1"
                :focus-index="focusIndex"
                :parent-index="index"
                @clearFocusIndex="clearFocusIndex"
            ></MultiOptions>

            <div class="d-flex justify-content-between align-items-center flex-wrap">
                <div class="d-flex align-items-center">
                    <label class="mb-0 f-11 text-muted d-flex justify-content-between font-weight-bold">
                        <span>True/False</span>
                    </label>

                    <b-form-checkbox
                        :id="`multi-choice-boolean-${index}`"
                        :key="`option-${index}`"
                        v-model="itemData.hasBooleanOptions"
                        name="options"
                        class="f-14 text-muted ml-2 mr-n2"
                        tabindex="-1"
                        switch
                        :value-field="itemData.hasBooleanOptions"
                        @change="toggleBooleanOptions"
                    ></b-form-checkbox>
                </div>
                <div class="pl-2">
                    <label class="mb-0 f-11 text-muted d-flex justify-content-between">
                        <span class="font-weight-bold">Columns</span>
                        <span class="text-secondary">{{ itemData.number_of_columns }}</span>
                    </label>
                    <b-form-input
                        v-model="itemData.number_of_columns"
                        type="range"
                        min="1"
                        :max="showBooleanOptions ? 2 : 4"
                        @change="updateData()"
                    ></b-form-input>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import BooleanOptions from '../BooleanOptions.vue'
import MultiOptions from '../MultiOptions.vue'
import DocumentHelper from '../../mixins/DocumentHelper'

export default {
    name: 'MultipleChoice',
    components: {
        VueEditor,
        MultiOptions,
        BooleanOptions,
    },
    mixins: [DocumentHelper],
    props: {
        data: { type: Object, required: true },
        index: { type: Number, required: true },
        clickedIndex: { type: Number, default: null },
    },
    data() {
        return {
            showWorksheetTitle: true,
            showStudentInfo: true,
            fonts: [
                { value: 'Multiple choice', text: 'Multiple choice' },
                { value: 'Handwriting Lines', text: 'Handwriting Lines' },
            ],
            customToolbar: [['bold', 'italic', 'underline'], [], []],
            editorSettings: {
                formats: ['bold', 'italic', 'underline', 'script'],
                modules: {
                    keyboard: {
                        bindings: {
                            tab: {
                                key: 9,
                                handler: function () {
                                    return true
                                },
                            },
                        },
                    },
                },
            },
            focusIndex: null,
        }
    },
    computed: {
        itemData: {
            get() {
                return this.data
            },
            set(value) {
                this.itemData = value
            },
        },
        booleanOptions: {
            get() {
                return this.itemData.booleanOptions
            },
            set(choices) {
                this.itemData.booleanOptions = choices
                this.$emit('change', this.itemData)
            },
        },
        subtitleInput: {
            get() {
                return this.itemData.subtitle
            },
            set(value) {
                this.itemData.subtitle = value
                this.$emit('change', this.itemData)
            },
        },
        showBooleanOptions() {
            return this.data.hasBooleanOptions
        },
    },
    watch: {
        clickedIndex(val) {
            if (val != null) this.updateFocusIndex(val)
        },
    },
    mounted() {
        if (this.clickedIndex != null) this.updateFocusIndex(this.clickedIndex)
        this.setCursorOnFocus()
    },
    methods: {
        updateData($event = null) {
            if ($event) {
                if ($event.ops && $event.ops[$event.ops?.length - 1].insert === '\t') {
                    this.focusIndex = 0
                }
            } else {
                this.$emit('change', this.itemData)
            }
        },
        addOption() {
            this.itemData.options.push({
                answer: '',
                correct: false,
            })
            this.updateData()
        },
        clearFocusIndex() {
            this.focusIndex = null
        },
        updateFocusIndex(val) {
            this.focusIndex = val
        },
        onEditorBlur() {
            this.$refs.editorContainer.classList.remove('active')
        },
        setCursorOnFocus() {
            this.$nextTick(() => {
                this.setCursorPositionOnFocus(`widget_${this.index}`, this.subtitleInput?.length || 0)
                this.$refs.editorContainer.classList.add('active')
            })
        },
        toggleBooleanOptions(value) {
            if (value) {
                this.itemData.stashedOptions = this.itemData.options
                this.focusIndex = null
                this.itemData.number_of_columns = Math.min(this.itemData.number_of_columns, 2)
                this.updateData()
            } else {
                this.itemData.options = this.itemData.stashedOptions
                this.focusIndex = 0
                this.updateData()
            }
        },
    },
}
</script>
